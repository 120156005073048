import React, { useState } from "react"
import NavbarLinks from "./NavbarLinks"
import Logo from "../../images/logos/logo.svg"
import styled from 'styled-components'

const isBrowser = typeof window !== `undefined`

if (isBrowser) {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("navbar").style.top = "0";
        } else {
            document.getElementById("navbar").style.top = "-120px";
        }
        prevScrollpos = currentScrollPos;
    }
}


const LogoContainer = styled.div`
    padding-top: 2em;
    max-width: 110px;
    flex: 0 1 110px;

    @media (max-width: 768px) {
        max-width: 80px;
        padding-top: 1em;

    }
`

const Navigation = styled.nav`
    transition: top 200ms ease-in;
    font-family: 'Roboto';
    height: 14vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    justify-content: space-between;
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.1);
    margin: 0 auto;
    padding: 0 5vw ;
    z-index: 100;
    align-self: center;

    @media (max-width: 768px) {
        display: flex;
        position: fixed;
        height: 8vh;
        top: 0;
        left: 0;
        right: 0;
        left: 0;
    }
`
const Toggle = styled.div`
    display: none;
    height: 100%;
    cursor: pointer;
    padding: 0 10vw;

    @media (max-width: 768px) {
        display: flex;
    }
`
const Navbox = styled.div`
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        position: fixed;
        width: 100%;
        justify-content: flex-start;
        padding-top: 10vh;
        background-color: rgba(0, 0, 0, 0.9);
        transition: all 0.3s ease-in;
        top: 8vh;
        left: ${props => (props.open ? "-100%" : "0")};
    }
`
const Hamburger = styled.div`
    background-color: #fff;
    width: 30px;
    height: 3px;
    transition: all .3s linear;
    align-self: center;
    position: relative;
    transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

    ::before,
    ::after {
        width: 30px;
        height: 3px;
        background-color: #fff;
        content: "";
        position: absolute;
        transition: all 0.3s linear;
    }

    ::before {
        transform: ${props =>
            props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
        top: -10px;
    }

    ::after {
        opacity: ${props => (props.open ? "0" : "1")};
        transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
        top: 10px;
    }
`

function Navbar () {
    const [navbarOpen, setNavbarOpen] = useState(false)

    return (
        <header id = "header">
            <Navigation id = "navbar">
                <LogoContainer>
                    <a href = "/">
                        <img
                            src={Logo}
                            alt="LRL Logo"
                        />
                    </a>
                </LogoContainer>

                <Toggle
                    navbarOpen = {navbarOpen}
                    onClick = { () => setNavbarOpen(!navbarOpen) }
                >
                    { navbarOpen? <Hamburger open /> : <Hamburger /> }
                </Toggle>

                { navbarOpen? (<Navbox> <NavbarLinks /> </Navbox>) : (<Navbox open> <NavbarLinks /> </Navbox>) }
            </Navigation>
        </header>
    )
}

export default Navbar