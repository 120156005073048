import React from "react"
import "../styles/redbutton.css"

function RedButton(props) {
    let url = props.link;
    let text = props.text;
    return (
        <a href={url} target ="_blank" rel="noopener noreferrer">
            <button className ="red-button" type = "button"> {text}</button>      
        </a>
    )
}

export default RedButton